@mixin primer-colors-dark_dimmed {
  & {
    --color-canvas-default-transparent: rgba(34,39,46,0);
    --color-page-header-bg: #22272e;
    --color-marketing-icon-primary: #6cb6ff;
    --color-marketing-icon-secondary: #316dca;
    --color-diff-blob-addition-num-text: #adbac7;
    --color-diff-blob-addition-fg: #adbac7;
    --color-diff-blob-addition-num-bg: rgba(87,171,90,0.3);
    --color-diff-blob-addition-line-bg: rgba(70,149,74,0.15);
    --color-diff-blob-addition-word-bg: rgba(70,149,74,0.4);
    --color-diff-blob-deletion-num-text: #adbac7;
    --color-diff-blob-deletion-fg: #adbac7;
    --color-diff-blob-deletion-num-bg: rgba(229,83,75,0.3);
    --color-diff-blob-deletion-line-bg: rgba(229,83,75,0.15);
    --color-diff-blob-deletion-word-bg: rgba(229,83,75,0.4);
    --color-diff-blob-hunk-num-bg: rgba(65,132,228,0.4);
    --color-diff-blob-expander-icon: #768390;
    --color-diff-blob-selected-line-highlight-mix-blend-mode: screen;
    --color-diffstat-deletion-border: rgba(205,217,229,0.1);
    --color-diffstat-addition-border: rgba(205,217,229,0.1);
    --color-diffstat-addition-bg: #57ab5a;
    --color-search-keyword-hl: rgba(198,144,38,0.4);
    --color-prettylights-syntax-comment: #768390;
    --color-prettylights-syntax-constant: #6cb6ff;
    --color-prettylights-syntax-entity: #dcbdfb;
    --color-prettylights-syntax-storage-modifier-import: #adbac7;
    --color-prettylights-syntax-entity-tag: #8ddb8c;
    --color-prettylights-syntax-keyword: #f47067;
    --color-prettylights-syntax-string: #96d0ff;
    --color-prettylights-syntax-variable: #f69d50;
    --color-prettylights-syntax-brackethighlighter-unmatched: #e5534b;
    --color-prettylights-syntax-invalid-illegal-text: #cdd9e5;
    --color-prettylights-syntax-invalid-illegal-bg: #922323;
    --color-prettylights-syntax-carriage-return-text: #cdd9e5;
    --color-prettylights-syntax-carriage-return-bg: #ad2e2c;
    --color-prettylights-syntax-string-regexp: #8ddb8c;
    --color-prettylights-syntax-markup-list: #eac55f;
    --color-prettylights-syntax-markup-heading: #316dca;
    --color-prettylights-syntax-markup-italic: #adbac7;
    --color-prettylights-syntax-markup-bold: #adbac7;
    --color-prettylights-syntax-markup-deleted-text: #ffd8d3;
    --color-prettylights-syntax-markup-deleted-bg: #78191b;
    --color-prettylights-syntax-markup-inserted-text: #b4f1b4;
    --color-prettylights-syntax-markup-inserted-bg: #1b4721;
    --color-prettylights-syntax-markup-changed-text: #ffddb0;
    --color-prettylights-syntax-markup-changed-bg: #682d0f;
    --color-prettylights-syntax-markup-ignored-text: #adbac7;
    --color-prettylights-syntax-markup-ignored-bg: #255ab2;
    --color-prettylights-syntax-meta-diff-range: #dcbdfb;
    --color-prettylights-syntax-brackethighlighter-angle: #768390;
    --color-prettylights-syntax-sublimelinter-gutter-mark: #545d68;
    --color-prettylights-syntax-constant-other-reference-link: #96d0ff;
    --color-codemirror-text: #adbac7;
    --color-codemirror-bg: #22272e;
    --color-codemirror-gutters-bg: #22272e;
    --color-codemirror-guttermarker-text: #22272e;
    --color-codemirror-guttermarker-subtle-text: #636e7b;
    --color-codemirror-linenumber-text: #768390;
    --color-codemirror-cursor: #adbac7;
    --color-codemirror-selection-bg: rgba(65,132,228,0.4);
    --color-codemirror-activeline-bg: rgba(99,110,123,0.1);
    --color-codemirror-matchingbracket-text: #adbac7;
    --color-codemirror-lines-bg: #22272e;
    --color-codemirror-syntax-comment: #768390;
    --color-codemirror-syntax-constant: #6cb6ff;
    --color-codemirror-syntax-entity: #dcbdfb;
    --color-codemirror-syntax-keyword: #f47067;
    --color-codemirror-syntax-storage: #f47067;
    --color-codemirror-syntax-string: #96d0ff;
    --color-codemirror-syntax-support: #6cb6ff;
    --color-codemirror-syntax-variable: #f69d50;
    --color-checks-bg: #1c2128;
    --color-checks-run-border-width: 1px;
    --color-checks-container-border-width: 1px;
    --color-checks-text-primary: #adbac7;
    --color-checks-text-secondary: #768390;
    --color-checks-text-link: #539bf5;
    --color-checks-btn-icon: #768390;
    --color-checks-btn-hover-icon: #adbac7;
    --color-checks-btn-hover-bg: rgba(99,110,123,0.1);
    --color-checks-input-text: #768390;
    --color-checks-input-placeholder-text: #636e7b;
    --color-checks-input-focus-text: #adbac7;
    --color-checks-input-bg: #2d333b;
    --color-checks-input-shadow: 0 0 0 1px (obj) => get_1.default(obj, path);
    --color-checks-donut-error: #e5534b;
    --color-checks-donut-pending: #c69026;
    --color-checks-donut-success: #46954a;
    --color-checks-donut-neutral: #768390;
    --color-checks-dropdown-text: #adbac7;
    --color-checks-dropdown-bg: #2d333b;
    --color-checks-dropdown-border: #444c56;
    --color-checks-dropdown-shadow: rgba(28,33,40,0.3);
    --color-checks-dropdown-hover-text: #adbac7;
    --color-checks-dropdown-hover-bg: rgba(99,110,123,0.1);
    --color-checks-dropdown-btn-hover-text: #adbac7;
    --color-checks-dropdown-btn-hover-bg: rgba(99,110,123,0.1);
    --color-checks-scrollbar-thumb-bg: rgba(99,110,123,0.4);
    --color-checks-header-label-text: #768390;
    --color-checks-header-label-open-text: #adbac7;
    --color-checks-header-border: #373e47;
    --color-checks-header-icon: #768390;
    --color-checks-line-text: #768390;
    --color-checks-line-num-text: #636e7b;
    --color-checks-line-timestamp-text: #636e7b;
    --color-checks-line-hover-bg: rgba(99,110,123,0.1);
    --color-checks-line-selected-bg: rgba(65,132,228,0.15);
    --color-checks-line-selected-num-text: #539bf5;
    --color-checks-line-dt-fm-text: #cdd9e5;
    --color-checks-line-dt-fm-bg: #966600;
    --color-checks-gate-bg: rgba(174,124,20,0.15);
    --color-checks-gate-text: #768390;
    --color-checks-gate-waiting-text: #c69026;
    --color-checks-step-header-open-bg: #2d333b;
    --color-checks-step-error-text: #e5534b;
    --color-checks-step-warning-text: #c69026;
    --color-checks-logline-text: #768390;
    --color-checks-logline-num-text: #636e7b;
    --color-checks-logline-debug-text: #986ee2;
    --color-checks-logline-error-text: #768390;
    --color-checks-logline-error-num-text: #636e7b;
    --color-checks-logline-error-bg: rgba(229,83,75,0.15);
    --color-checks-logline-warning-text: #768390;
    --color-checks-logline-warning-num-text: #c69026;
    --color-checks-logline-warning-bg: rgba(174,124,20,0.15);
    --color-checks-logline-command-text: #539bf5;
    --color-checks-logline-section-text: #57ab5a;
    --color-checks-ansi-black: #22272e;
    --color-checks-ansi-black-bright: #2d333b;
    --color-checks-ansi-white: #909dab;
    --color-checks-ansi-white-bright: #909dab;
    --color-checks-ansi-gray: #636e7b;
    --color-checks-ansi-red: #f47067;
    --color-checks-ansi-red-bright: #ff938a;
    --color-checks-ansi-green: #57ab5a;
    --color-checks-ansi-green-bright: #6bc46d;
    --color-checks-ansi-yellow: #c69026;
    --color-checks-ansi-yellow-bright: #daaa3f;
    --color-checks-ansi-blue: #539bf5;
    --color-checks-ansi-blue-bright: #6cb6ff;
    --color-checks-ansi-magenta: #b083f0;
    --color-checks-ansi-magenta-bright: #dcbdfb;
    --color-checks-ansi-cyan: #76e3ea;
    --color-checks-ansi-cyan-bright: #b3f0ff;
    --color-project-header-bg: #22272e;
    --color-project-sidebar-bg: #2d333b;
    --color-project-gradient-in: #2d333b;
    --color-project-gradient-out: rgba(45,51,59,0);
    --color-mktg-btn-bg: #f6f8fa;
    --color-mktg-btn-shadow-outline: rgb(255 255 255 / 25%) 0 0 0 1px inset;
    --color-mktg-btn-shadow-focus: rgb(255 255 255 / 25%) 0 0 0 4px;
    --color-mktg-btn-shadow-hover: 0 4px 7px rgba(0, 0, 0, 0.15), 0 100px 80px rgba(255, 255, 255, 0.02), 0 42px 33px rgba(255, 255, 255, 0.024), 0 22px 18px rgba(255, 255, 255, 0.028), 0 12px 10px rgba(255, 255, 255, 0.034), 0 7px 5px rgba(255, 255, 255, 0.04), 0 3px 2px rgba(255, 255, 255, 0.07);
    --color-mktg-btn-shadow-hover-muted: rgb(255 255 255) 0 0 0 2px inset;
    --color-avatar-bg: rgba(205,217,229,0.1);
    --color-avatar-border: rgba(205,217,229,0.1);
    --color-avatar-stack-fade: #444c56;
    --color-avatar-stack-fade-more: #373e47;
    --color-avatar-child-shadow: -2px -2px 0 #22272e;
    --color-topic-tag-border: rgba(0,0,0,0);
    --color-counter-border: rgba(0,0,0,0);
    --color-select-menu-backdrop-border: #545d68;
    --color-select-menu-tap-highlight: rgba(68,76,86,0.5);
    --color-select-menu-tap-focus-bg: #143d79;
    --color-overlay-shadow: 0 0 0 1px #444c56, 0 16px 32px rgba(28,33,40,0.85);
    --color-header-text: rgba(205,217,229,0.7);
    --color-header-bg: #2d333b;
    --color-header-divider: #768390;
    --color-header-logo: #cdd9e5;
    --color-header-search-bg: #22272e;
    --color-header-search-border: #444c56;
    --color-sidenav-selected-bg: #373e47;
    --color-menu-bg-active: #2d333b;
    --color-input-disabled-bg: rgba(99,110,123,0);
    --color-timeline-badge-bg: #373e47;
    --color-ansi-black: #545d68;
    --color-ansi-black-bright: #636e7b;
    --color-ansi-white: #909dab;
    --color-ansi-white-bright: #cdd9e5;
    --color-ansi-gray: #636e7b;
    --color-ansi-red: #f47067;
    --color-ansi-red-bright: #ff938a;
    --color-ansi-green: #57ab5a;
    --color-ansi-green-bright: #6bc46d;
    --color-ansi-yellow: #c69026;
    --color-ansi-yellow-bright: #daaa3f;
    --color-ansi-blue: #539bf5;
    --color-ansi-blue-bright: #6cb6ff;
    --color-ansi-magenta: #b083f0;
    --color-ansi-magenta-bright: #dcbdfb;
    --color-ansi-cyan: #39c5cf;
    --color-ansi-cyan-bright: #56d4dd;
    --color-btn-text: #adbac7;
    --color-btn-bg: #373e47;
    --color-btn-border: rgba(205,217,229,0.1);
    --color-btn-shadow: 0 0 transparent;
    --color-btn-inset-shadow: 0 0 transparent;
    --color-btn-hover-bg: #444c56;
    --color-btn-hover-border: #768390;
    --color-btn-active-bg: hsla(213,12%,27%,1);
    --color-btn-active-border: #636e7b;
    --color-btn-selected-bg: #2d333b;
    --color-btn-focus-bg: #373e47;
    --color-btn-focus-border: #768390;
    --color-btn-focus-shadow: 0 0 0 3px rgba(118,131,144,0.3);
    --color-btn-shadow-active: inset 0 0.15em 0.3em rgba(28,33,40,0.15);
    --color-btn-shadow-input-focus: 0 0 0 0.2em rgba(49,109,202,0.3);
    --color-btn-counter-bg: #444c56;
    --color-btn-primary-text: #ffffff;
    --color-btn-primary-bg: #347d39;
    --color-btn-primary-border: rgba(205,217,229,0.1);
    --color-btn-primary-shadow: 0 0 transparent;
    --color-btn-primary-inset-shadow: 0 0 transparent;
    --color-btn-primary-hover-bg: #46954a;
    --color-btn-primary-hover-border: rgba(205,217,229,0.1);
    --color-btn-primary-selected-bg: #347d39;
    --color-btn-primary-selected-shadow: 0 0 transparent;
    --color-btn-primary-disabled-text: rgba(205,217,229,0.5);
    --color-btn-primary-disabled-bg: rgba(52,125,57,0.6);
    --color-btn-primary-disabled-border: rgba(205,217,229,0.1);
    --color-btn-primary-focus-bg: #347d39;
    --color-btn-primary-focus-border: rgba(205,217,229,0.1);
    --color-btn-primary-focus-shadow: 0 0 0 3px rgba(46,164,79,0.4);
    --color-btn-primary-icon: #cdd9e5;
    --color-btn-primary-counter-bg: rgba(205,217,229,0.2);
    --color-btn-outline-text: #539bf5;
    --color-btn-outline-hover-text: #539bf5;
    --color-btn-outline-hover-bg: #444c56;
    --color-btn-outline-hover-border: rgba(205,217,229,0.1);
    --color-btn-outline-hover-shadow: 0 1px 0 rgba(28,33,40,0.1);
    --color-btn-outline-hover-inset-shadow: inset 0 1px 0 rgba(205,217,229,0.03);
    --color-btn-outline-hover-counter-bg: rgba(205,217,229,0.2);
    --color-btn-outline-selected-text: #cdd9e5;
    --color-btn-outline-selected-bg: #1b4b91;
    --color-btn-outline-selected-border: rgba(205,217,229,0.1);
    --color-btn-outline-selected-shadow: 0 0 transparent;
    --color-btn-outline-disabled-text: rgba(83,155,245,0.5);
    --color-btn-outline-disabled-bg: #22272e;
    --color-btn-outline-disabled-counter-bg: rgba(49,109,202,0.05);
    --color-btn-outline-focus-border: rgba(205,217,229,0.1);
    --color-btn-outline-focus-shadow: 0 0 0 3px rgba(37,90,178,0.4);
    --color-btn-outline-counter-bg: rgba(49,109,202,0.1);
    --color-btn-danger-text: #e5534b;
    --color-btn-danger-hover-text: #cdd9e5;
    --color-btn-danger-hover-bg: #c93c37;
    --color-btn-danger-hover-border: #e5534b;
    --color-btn-danger-hover-shadow: 0 0 transparent;
    --color-btn-danger-hover-inset-shadow: 0 0 transparent;
    --color-btn-danger-hover-icon: #cdd9e5;
    --color-btn-danger-hover-counter-bg: rgba(255,255,255,0.2);
    --color-btn-danger-selected-text: #ffffff;
    --color-btn-danger-selected-bg: #ad2e2c;
    --color-btn-danger-selected-border: #f47067;
    --color-btn-danger-selected-shadow: 0 0 transparent;
    --color-btn-danger-disabled-text: rgba(229,83,75,0.5);
    --color-btn-danger-disabled-bg: #22272e;
    --color-btn-danger-disabled-counter-bg: rgba(201,60,55,0.05);
    --color-btn-danger-focus-border: #e5534b;
    --color-btn-danger-focus-shadow: 0 0 0 3px rgba(229,83,75,0.4);
    --color-btn-danger-counter-bg: rgba(201,60,55,0.1);
    --color-btn-danger-icon: #e5534b;
    --color-underlinenav-icon: #636e7b;
    --color-underlinenav-border-hover: rgba(99,110,123,0.4);
    --color-action-list-item-inline-divider: rgba(68,76,86,0.48);
    --color-action-list-item-default-hover-bg: rgba(144,157,171,0.12);
    --color-action-list-item-default-hover-border: rgba(0,0,0,0);
    --color-action-list-item-default-active-bg: rgba(144,157,171,0.2);
    --color-action-list-item-default-active-border: rgba(0,0,0,0);
    --color-action-list-item-default-selected-bg: rgba(144,157,171,0.08);
    --color-action-list-item-danger-hover-bg: rgba(229,83,75,0.16);
    --color-action-list-item-danger-active-bg: rgba(229,83,75,0.24);
    --color-action-list-item-danger-hover-text: #f47067;
    --color-switch-track-bg: #1c2128;
    --color-switch-track-border: #636e7b;
    --color-switch-track-checked-bg: rgba(49,109,202,0.35);
    --color-switch-track-checked-hover-bg: rgba(49,109,202,0.5);
    --color-switch-track-checked-active-bg: rgba(49,109,202,0.65);
    --color-switch-track-checked-border: #539bf5;
    --color-switch-knob-checked-bg: #316dca;
    --color-switch-knob-checked-disabled-bg: #545d68;
    --color-fg-default: #adbac7;
    --color-fg-muted: #768390;
    --color-fg-subtle: #636e7b;
    --color-fg-on-emphasis: #cdd9e5;
    --color-canvas-default: #22272e;
    --color-canvas-overlay: #2d333b;
    --color-canvas-inset: #1c2128;
    --color-canvas-subtle: #2d333b;
    --color-border-default: #444c56;
    --color-border-muted: #373e47;
    --color-border-subtle: rgba(205,217,229,0.1);
    --color-shadow-small: 0 0 transparent;
    --color-shadow-medium: 0 3px 6px #1c2128;
    --color-shadow-large: 0 8px 24px #1c2128;
    --color-shadow-extra-large: 0 12px 48px #1c2128;
    --color-neutral-emphasis-plus: #636e7b;
    --color-neutral-emphasis: #636e7b;
    --color-neutral-muted: rgba(99,110,123,0.4);
    --color-neutral-subtle: rgba(99,110,123,0.1);
    --color-accent-fg: #539bf5;
    --color-accent-emphasis: #316dca;
    --color-accent-muted: rgba(65,132,228,0.4);
    --color-accent-subtle: rgba(65,132,228,0.15);
    --color-success-fg: #57ab5a;
    --color-success-emphasis: #347d39;
    --color-success-muted: rgba(70,149,74,0.4);
    --color-success-subtle: rgba(70,149,74,0.15);
    --color-attention-fg: #c69026;
    --color-attention-emphasis: #966600;
    --color-attention-muted: rgba(174,124,20,0.4);
    --color-attention-subtle: rgba(174,124,20,0.15);
    --color-severe-fg: #cc6b2c;
    --color-severe-emphasis: #ae5622;
    --color-severe-muted: rgba(204,107,44,0.4);
    --color-severe-subtle: rgba(204,107,44,0.15);
    --color-danger-fg: #e5534b;
    --color-danger-emphasis: #c93c37;
    --color-danger-muted: rgba(229,83,75,0.4);
    --color-danger-subtle: rgba(229,83,75,0.15);
    --color-open-fg: #57ab5a;
    --color-open-emphasis: #347d39;
    --color-open-muted: rgba(70,149,74,0.4);
    --color-open-subtle: rgba(70,149,74,0.15);
    --color-closed-fg: #e5534b;
    --color-closed-emphasis: #c93c37;
    --color-closed-muted: rgba(229,83,75,0.4);
    --color-closed-subtle: rgba(229,83,75,0.15);
    --color-done-fg: #986ee2;
    --color-done-emphasis: #8256d0;
    --color-done-muted: rgba(152,110,226,0.4);
    --color-done-subtle: rgba(152,110,226,0.15);
    --color-sponsors-fg: #c96198;
    --color-sponsors-emphasis: #ae4c82;
    --color-sponsors-muted: rgba(201,97,152,0.4);
    --color-sponsors-subtle: rgba(201,97,152,0.15);
    --color-primer-fg-disabled: #545d68;
    --color-primer-canvas-backdrop: rgba(28,33,40,0.8);
    --color-primer-canvas-sticky: rgba(34,39,46,0.95);
    --color-primer-border-active: #EC775C;
    --color-primer-border-contrast: rgba(205,217,229,0.2);
    --color-primer-shadow-highlight: 0 0 transparent;
    --color-primer-shadow-inset: 0 0 transparent;
    --color-primer-shadow-focus: 0 0 0 3px #143d79;
    --color-scale-black: #1c2128;
    --color-scale-white: #cdd9e5;
    --color-scale-gray-0: #cdd9e5;
    --color-scale-gray-1: #adbac7;
    --color-scale-gray-2: #909dab;
    --color-scale-gray-3: #768390;
    --color-scale-gray-4: #636e7b;
    --color-scale-gray-5: #545d68;
    --color-scale-gray-6: #444c56;
    --color-scale-gray-7: #373e47;
    --color-scale-gray-8: #2d333b;
    --color-scale-gray-9: #22272e;
    --color-scale-blue-0: #c6e6ff;
    --color-scale-blue-1: #96d0ff;
    --color-scale-blue-2: #6cb6ff;
    --color-scale-blue-3: #539bf5;
    --color-scale-blue-4: #4184e4;
    --color-scale-blue-5: #316dca;
    --color-scale-blue-6: #255ab2;
    --color-scale-blue-7: #1b4b91;
    --color-scale-blue-8: #143d79;
    --color-scale-blue-9: #0f2d5c;
    --color-scale-green-0: #b4f1b4;
    --color-scale-green-1: #8ddb8c;
    --color-scale-green-2: #6bc46d;
    --color-scale-green-3: #57ab5a;
    --color-scale-green-4: #46954a;
    --color-scale-green-5: #347d39;
    --color-scale-green-6: #2b6a30;
    --color-scale-green-7: #245829;
    --color-scale-green-8: #1b4721;
    --color-scale-green-9: #113417;
    --color-scale-yellow-0: #fbe090;
    --color-scale-yellow-1: #eac55f;
    --color-scale-yellow-2: #daaa3f;
    --color-scale-yellow-3: #c69026;
    --color-scale-yellow-4: #ae7c14;
    --color-scale-yellow-5: #966600;
    --color-scale-yellow-6: #805400;
    --color-scale-yellow-7: #6c4400;
    --color-scale-yellow-8: #593600;
    --color-scale-yellow-9: #452700;
    --color-scale-orange-0: #ffddb0;
    --color-scale-orange-1: #ffbc6f;
    --color-scale-orange-2: #f69d50;
    --color-scale-orange-3: #e0823d;
    --color-scale-orange-4: #cc6b2c;
    --color-scale-orange-5: #ae5622;
    --color-scale-orange-6: #94471b;
    --color-scale-orange-7: #7f3913;
    --color-scale-orange-8: #682d0f;
    --color-scale-orange-9: #4d210c;
    --color-scale-red-0: #ffd8d3;
    --color-scale-red-1: #ffb8b0;
    --color-scale-red-2: #ff938a;
    --color-scale-red-3: #f47067;
    --color-scale-red-4: #e5534b;
    --color-scale-red-5: #c93c37;
    --color-scale-red-6: #ad2e2c;
    --color-scale-red-7: #922323;
    --color-scale-red-8: #78191b;
    --color-scale-red-9: #5D0F12;
    --color-scale-purple-0: #eedcff;
    --color-scale-purple-1: #dcbdfb;
    --color-scale-purple-2: #dcbdfb;
    --color-scale-purple-3: #b083f0;
    --color-scale-purple-4: #986ee2;
    --color-scale-purple-5: #8256d0;
    --color-scale-purple-6: #6b44bc;
    --color-scale-purple-7: #5936a2;
    --color-scale-purple-8: #472c82;
    --color-scale-purple-9: #352160;
    --color-scale-pink-0: #ffd7eb;
    --color-scale-pink-1: #ffb3d8;
    --color-scale-pink-2: #fc8dc7;
    --color-scale-pink-3: #e275ad;
    --color-scale-pink-4: #c96198;
    --color-scale-pink-5: #ae4c82;
    --color-scale-pink-6: #983b6e;
    --color-scale-pink-7: #7e325a;
    --color-scale-pink-8: #69264a;
    --color-scale-pink-9: #551639;
    --color-scale-coral-0: #FFDACF;
    --color-scale-coral-1: #FFB9A5;
    --color-scale-coral-2: #F79981;
    --color-scale-coral-3: #EC775C;
    --color-scale-coral-4: #DE5B41;
    --color-scale-coral-5: #C2442D;
    --color-scale-coral-6: #A93524;
    --color-scale-coral-7: #8D291B;
    --color-scale-coral-8: #771D13;
    --color-scale-coral-9: #5D1008;
  }
}
